// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import { auth } from './firebase';

let app: any;

auth.onAuthStateChanged(async user => {
  if (user) {
    console.debug("Logged in")
    router.push({ name: '', query: { redirect: '/' } });
  } else {
    console.debug("Logged out")
    router.push({ name: '', query: { redirect: '/login' } });
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }
});


// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   vuetify,
//   i18n,
//   render: h => h(App),
// }).$mount('#app')
