import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/dashboard/pages/Login.vue';
import Dashboard from '@/views/dashboard/Index.vue';
import { auth } from '@/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: {
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/dashboard/Dashboard'),
      },
      {
        name: 'Charts',
        path: '/pages/chart',
        component: () => import('@/views/dashboard/pages/Charts'),
      },
    ],
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


// Nav Guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.currentUser) {
      next({
        path: `/login`,
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (auth.currentUser) {
      next({
        path: `/`,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;