<template>
  <v-app
    :style="{
      background: `url(${bgImage}) no-repeat center center fixed`,
      backgroundSize: `cover`
    }"
  >
    <v-container>
      <v-row justify="center" class="mt-5 mb-5" align="center">
        <p style="margin-top: 64px; font-size: 40px; color: white">Se Sammen Dashboard</p>
      </v-row>
      <v-row justify="center" align="center">
        <v-form ref="form" v-model="valid">
          <v-card class="mt-5" style="border-radius: 8px" width="400">
            <v-container>
              <v-card-title class="headline">Log ind</v-card-title>
              <v-card-text>
                <v-text-field v-model="email" placeholder=" " :rules="emailRules" label="Email" type="email" required></v-text-field>

                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  placeholder=" "
                  label="Password"
                  type="password"
                  v-on:keyup.enter="submitForm"
                  required
                ></v-text-field>

                <v-btn :disabled="!valid" color="info" class="mr-4" @click.prevent="submitForm"> Log ind </v-btn>
              </v-card-text>
            </v-container>
          </v-card>
        </v-form>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { auth } from "@/firebase";

export default {
  data() {
    return {
      bgImage: "https://farm4.staticflickr.com/3854/32764887833_7c91946505_k.jpg",
      valid: false,
      password: "",
      passwordRules: [v => !!v || "Password is required"],
      email: "",
      emailRules: [v => !!v || "E-mail is required", v => /.+@.+/.test(v) || "E-mail must be valid"]
    };
  },

  watch: {
    getAuth(value) {
      if (value) {
        this.$router.push({
          path: "/"
        });
      }
    }
  },
  methods: {
    async submitForm() {
      await auth.signInWithEmailAndPassword(this.email, this.password);
    }
  }
};
</script>