import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

const firebaseConfig = {
    // TODO include as .env
    apiKey: "AIzaSyAYBjHyvq4p8FK7FHwYo7GuM9aQ8n-J0fU",
    authDomain: "dr-sesammen.firebaseapp.com",
    databaseURL:
        "https://dr-sesammen-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dr-sesammen",
    storageBucket: "dr-sesammen.appspot.com",
    messagingSenderId: "387071501583",
    appId: "1:387071501583:web:383edba9b20fe140f4ca7a",
    measurementId: "G-VJ5QHR0VZL",

    // apiKey: "AIzaSyBmLD_PGD5nJE_sOB2mkeaEgJBG7ldie_M",
    // authDomain: "dr-sammen-preprod.firebaseapp.com",
    // databaseURL: "https://dr-sammen-preprod-default-rtdb.europe-west1.firebasedatabase.app/",
    // projectId: "dr-sammen-preprod",
    // storageBucket: "dr-sammen-preprod.appspot.com",
    // messagingSenderId: "956632578028",
    // appId: "1:956632578028:web:5d4aa335b33bd2520bb2c9"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();

export {
    auth,
    storage,
    database,
};
